import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { QuestsStore, QuestsState } from './quests.store.js';
// import { QuestsStore, QuestsState, QuestsUIState } from './quests.store';

@Injectable({ providedIn: 'root' })
export class QuestsQuery extends QueryEntity<QuestsState> {
  // override ui!: EntityUIQuery<QuestsUIState>;
  constructor(protected override store: QuestsStore) {
    super(store);
    // this.createUIQuery();
  }

  getQuestReview() {
    return (this.getValue().entities as any)['A8g1KCpIo2uJYKdtJx4Hq'];
  }

  getQuestComment() {
    return (this.getValue().entities as any)['CcCUsMsFiPAQai8fQan2B'];
  }

  getQuestReadingBook() {
    return (this.getValue().entities as any)['mqOe43EDPnV8c3qYtx5ln'];
  }
}
