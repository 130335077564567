import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DailyCheckinState {
  key: string;
}

export function createInitialState(): DailyCheckinState {
  return {
    key: 'value',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'daily-checkin', resettable: true, idKey: 'userId' })
export class DailyCheckinStore extends Store<DailyCheckinState> {
  constructor() {
    super(createInitialState());
  }
}
