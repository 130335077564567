import { Injectable } from '@angular/core';
import { CurrentUserFacade } from '@awread/core/users';
import { subDays } from 'date-fns';
import { of, tap, map } from 'rxjs';
import { QuestsApi } from '../apis/index.js';
import { DailyCheckinStore } from '../states/daily-checkin/index.js';
import { QuestsStore } from '../states/quests/index.js';

@Injectable({ providedIn: 'root' })
export class QuestsGear {
  constructor(
    private questsApi: QuestsApi,
    private questsStore: QuestsStore,
    private currentUserFacade: CurrentUserFacade,
    private dailyCheckinStore: DailyCheckinStore,
  ) { }

  getAllQuestsByUserId() {
    const currentUserId = this.currentUserFacade.getUserId();
    if (currentUserId) {
      return this.questsApi.getAllQuestsByUserId(currentUserId).pipe(tap((res) => this.questsStore.set(res)));
    } else return of([]);
  }

  checkClaimReward(questMilestoneId: string) {
    return this.questsApi.checkClaimReward(questMilestoneId);
  }

  updateQuestProcesses(quest: any) {
    const currentMilestoneLevel = quest.questProcesses.nodes[0].currentMilestoneLevel;
    const questMilestones = quest.questMilestones.nodes;
    const claimedLevel = quest.questProcesses.nodes[0].claimedLevel;
    const point = quest.questProcesses.nodes[0].point;
    return this.questsStore.update(quest.questId, {
      ...quest,
      questProcesses: {
        nodes: [
          {
            currentMilestoneLevel: currentMilestoneLevel === questMilestones.length ? currentMilestoneLevel : currentMilestoneLevel + 1,
            status: currentMilestoneLevel === questMilestones.length ? 'completed' : 'processing',
            claimedLevel: claimedLevel + 1,
            point: point,
          },
        ],
      },
    });
  }

  getDailyCheckin() {
    return this.questsApi.getDailyCheckin().pipe(
      tap((res: any) =>
        this.dailyCheckinStore.update({
          ...res,
          startToday: new Date(new Date().setUTCHours(0, 0, 0, 0)),
          // startYesterday: subDays(new Date().setUTCHours(0, 0, 0, 0), 1),
          rewardAmounts: ['50', '50', '50', '50', '50', '50', '100'],
          checkinToday: res ? (new Date(res.updatedAt) > new Date(new Date().setUTCHours(0, 0, 0, 0)) ? true : false) : false,
        }),
      ),
    );
  }

  dailyCheckin() {
    return this.questsApi.claimDailyCheckin();
  }

  updateDailyCheckin(type: 'reset' | 'update', dailyCheckin: any) {
    // console.log('gear', type, dailyCheckin);
    return this.dailyCheckinStore.update({
      ...dailyCheckin,
      currentPosition: type === 'update' ? dailyCheckin.currentPosition + 1 : 0,
      checkinToday: type === 'update' ? true : false,
      updatedAt: new Date(Date.now()),
    });
  }

  checkReadingQuest() {
    return this.questsApi.checkReadingQuest();
  }
}
