import { Injectable, inject } from '@angular/core';
import { PlausibleService, groupBy } from '@awread/kernel/services';
import { QuestsGear } from '../gears/index.js';
import { DailyCheckinQuery } from '../states/daily-checkin/index.js';
import { QuestsQuery } from '../states/quests/index.js';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestsFacade {
  questsQuery = inject(QuestsQuery);
  dailyCheckinQuery = inject(DailyCheckinQuery);

  quests$ = this.questsQuery.selectAll().pipe(
    map(quests => groupBy(quests, 'for'))
  );
  dailyCheckin$ = this.dailyCheckinQuery.select();
  constructor(
    private questsGear: QuestsGear,
    private plausibleService: PlausibleService
  ) { }

  getAllQuestsByUserId() {
    return this.questsGear.getAllQuestsByUserId();
  }

  checkClaimReward(questMilestoneId: string) {
    return this.questsGear.checkClaimReward(questMilestoneId);
  }

  updateQuestProcesses(quest: any) {
    return this.questsGear.updateQuestProcesses(quest);
  }

  getDailyCheckin() {
    return this.questsGear.getDailyCheckin();
  }

  dailyCheckin() {
    this.plausibleService.trackEvent('attendance');
    return this.questsGear.dailyCheckin();
  }

  updateDailyCheckin(type: 'reset' | 'update', dailyCheckin: any) {
    return this.questsGear.updateDailyCheckin(type, dailyCheckin);
  }

  checkReadingQuest() {
    return this.questsGear.checkReadingQuest();
  }
}
