import { Injectable } from '@angular/core';
import { CurrentUserFacade } from '@awread/core/users';
import { Apollo, gql } from 'apollo-angular';
import { subDays } from 'date-fns';
import { map, tap, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestsApi {
  constructor(private apollo: Apollo, private currentUserFacade: CurrentUserFacade) { }

  getAllQuestsByUserId(userId: string | undefined) {
    return this.apollo
      .query<any>({
        query: gql`
          query allQuestsByUserId($userId: UUID, $questStatus: String = "active") {
            quests(condition: { questStatus: $questStatus }) {
              nodes {
                for
                questId
                nodeId
                dateLimit
                description
                icon
                name
                periodLimit
                quantityLimit
                questCreatedAt
                questStatus
                questMilestones(orderBy: MILESTONE_LEVEL_ASC) {
                  nodes {
                    requireAmount
                    requirePoint
                    rewardPoint
                    rewardAmount
                    milestoneLevel
                    goal
                    questMilestoneId
                  }
                }
                questProcesses(condition: { userId: $userId }) {
                  nodes {
                    currentMilestoneLevel
                    status
                    claimedLevel
                    point
                  }
                }
              }
            }
          }
        `,
        variables: { userId },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['quests']?.['nodes']));
  }

  checkClaimReward(questMilestoneId: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation checkClaimReward($questMilestoneId: String, $userId: UUID!) {
            claimReward(input: { questMilestoneId: $questMilestoneId, userId: $userId }) {
              string
            }
          }
        `,
        variables: { questMilestoneId, userId: this.currentUserFacade.getUserId() },
      })
      .pipe(map((res) => res?.['data']?.['claimReward']?.['string']));
  }

  getDailyCheckin() {
    const startYesterday = subDays(new Date().setUTCHours(0, 0, 0, 0), 1);
    return this.apollo
      .query<any>({
        query: gql`
          query getDailyCheckin($userId: UUID!, $trackId: String, $greaterThan: Datetime) {
            tracks(condition: { trackId: $trackId }) {
              nodes {
                name
                trackId
                chains(
                  condition: { trackId: $trackId, userId: $userId }
                  filter: { currentPosition: { lessThan: 7 }, updatedAt: { greaterThan: $greaterThan } }
                  orderBy: CREATED_AT_DESC
                  first: 1
                ) {
                  nodes {
                    chainId
                    createdAt
                    currentPosition
                    nodeId
                    trackId
                    updatedAt
                    userId
                  }
                }
              }
            }
          }
        `,
        variables: { trackId: '7MX5FR_U6CsMysuWoAlR1', userId: this.currentUserFacade.getUserId(), greaterThan: startYesterday },
      })
      .pipe(map((res) => res?.['data']?.['tracks']?.['nodes'][0]?.['chains']?.['nodes'][0]));
  }

  claimDailyCheckin() {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation claimDailyCheckin($trackId: String, $userId: UUID!) {
            dailyCheckin(input: { trackId: $trackId, userId: $userId }) {
              string
            }
          }
        `,
        variables: { trackId: '7MX5FR_U6CsMysuWoAlR1', userId: this.currentUserFacade.getUserId() },
      })
      .pipe(map((res) => res?.['data']?.['dailyCheckin']?.['string']));
  }

  checkReadingQuest() {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation checkReadingQuest($clientMutationId: String = "") {
            checkReadingQuest(input: {clientMutationId: $clientMutationId}) {
              string
            }
          }
        `
      }).pipe(map((res) => res?.['data']?.['checkReadingQuest']?.['string']));
  }

}
