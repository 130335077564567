import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Quest } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface QuestsState extends EntityState<Quest>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface QuestUI {}

// export interface QuestsUIState extends EntityState<QuestUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'quests', resettable: true, idKey: 'questId' })
export class QuestsStore extends EntityStore<QuestsState> {
  // ui: EntityUIStore<QuestsUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
